var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard"},[_c('div',{staticClass:"dashboard__container"},[_c('div',{staticClass:"dashboard__container--header"},[_c('h1',[_vm._v("Jobs")]),_c('span',[_c('router-link',{staticClass:"color--text",attrs:{"to":{name: 'addweek'}}},[_c('button',{staticClass:"btn btn__outlined ml-3"},[_vm._v("Add Job")])])],1)]),_c('div',{staticClass:"dashboard__container--body"},[(!_vm.weeks || _vm.weeks.length == 0)?_c('Loader'):_vm._e(),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.weeks,"styleClass":"vgt-table condensed","search-options":{
            enabled: true,
            placeholder: 'Search this table',
          },"pagination-options":{
            enabled: true,
            mode: 'records',
            perPage: 25,
          }},on:{"on-row-click":_vm.onRowClick},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'created')?_c('span',[(props.row.created)?_c('span',[_vm._v(_vm._s(_vm.formatDate(props.row.created)))]):_vm._e()]):(props.column.field == 'published')?_c('span',[(props.row.published)?_c('i',{staticClass:"fa-solid fa-check",staticStyle:{"color":"green"}}):_vm._e()]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }